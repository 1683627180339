<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="tabletwo">
        <div class="beijing" v-if="nianfen_xianshi_yincang">
            <div class="box1">
                <p class="tit_box">产品销售目标</p>
                <p class="con">请选择创建产品销售目标的年份</p>
                <div class="con_box">
                    选择年份&nbsp;&nbsp;&nbsp;&nbsp;
                    <el-date-picker
                        v-model="nian_sj"
                        type="year"
                        format='yyyy年'
                        placeholder="选择年">
                    </el-date-picker>
                </div>
                <div class="foot">
                    <span @click="xuanze_nianfen_queding">确定</span>
                    <span @click="xuanze_nianfen_quxiao">取消</span>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="box_tit">
                <div class="box_tit_left">
                    <el-switch
                        class="demo"
                        v-model='yincang'
                        @change="yincang_xuanze"
                        active-color="#888888"
                        inactive-color="#ff4949"
                        active-text="隐藏"
                        inactive-text="显示">
                    </el-switch>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    上年销售数据
                </div>
                <p class="box_tit_center">{{time}}产品销售目标</p>
                <p class="box_tit_right" @click="dianji_lishi_chaxun">
                    历史查询<i></i>
                </p>
            </div>
            <div class="table_tit">
                <div class="table_tit_left">
                    <p class="xinjian" @click="nianfen_xianshi_yincang=true">新建</p>
                    <div class="table_tit_left_nianfen">
                        年份
                        <el-select v-model="time" @change='xuanze_nianfen_biao'>
                            <el-option
                                v-for="(i,index) in cunzai_shijian_list"
                                :key="index"
                                :label="i.time"
                                :value="i.time">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="table_tit_right">
                    单位：元
                    <!-- <el-switch
                        class="demo"
                        v-model='danwei'
                        @change="danwei_xuanze"
                        active-color="#ff4949"
                        inactive-color="#ff4949"
                        active-text="万元"
                        inactive-text="元">
                    </el-switch> -->
                </div>
            </div>
            <div class="table">
                <div class="table_left">
                    <p @click="bianji_chanpin">编辑产品</p>
                    <p @click="dianji_shanchu">删除</p>
                    <p @click="dianji_tiaozhengmubiao">调整目标</p>
                </div>
                <div class="table_right">
                    <p @click="dianji_guanbi">关闭</p>
                    <p :style="shifou_baocun?'background:#B8C1CE;border:0.01rem solid #B8C1CE;':''" @click="dianji_baocun">保存</p>   <!--shifou_baocun  true不能点false能点-->
                    <p :style="zhanshi==1?'background:#9a86db;color:#fff;border:0.01rem solid #9a86db;margin-right:0;':'margin-right:0;'" @click="dianji_chutu">出图</p>
                </div>
            </div>
            <div class="table_con">
                <!-- :summary-method="getSummaries"
                    :show-summary="showSum" -->
                <el-table
                    ref="multipleTable"
                    :data="zhanshi_xuanze_list"
                    tooltip-effect="dark"
                    border
                    max-height="600"
                    :cell-style="cellStyle"
                    :header-cell-style="headerCellStyle"
                    :summary-method="getSummaries"
                    :show-summary="showSum"
                    @selection-change="dianji_xuanzhong">
                <el-table-column
                    type="selection"
                    fixed
                    width="55">
                </el-table-column>
                <el-table-column
                    min-width="180"
                    fixed
                    label="产品编号">
                    <template slot-scope="scope">
                        {{ scope.row.chanpin_bianhao.length!=0?scope.row.chanpin_bianhao:'' }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="chanpin"
                    fixed
                    min-width="180"
                    label="产品">
                </el-table-column>
                <el-table-column
                    prop="shangnian_mubiao_xiaoshou_e"
                    min-width="180"
                    label="上年目标销售额"
                    v-if="!yincang"
                    >
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_mubiao_xiaoshou_e" placeholder="请填写">
                        {{zhanshi==1?scope.row.shangnian_mubiao_xiaoshou_e:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="shangnian_xiaoshou_e"
                    min-width="180"
                    v-if="!yincang"
                    label="上年销售额">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_xiaoshou_e" placeholder="请填写">
                        {{zhanshi==1?scope.row.shangnian_xiaoshou_e:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="shangnian_mubiao_xiaoshou_wancheng_lv"
                    min-width="180"
                    v-if="!yincang"
                    label="上年目标销售额完成率">
                </el-table-column>
                <el-table-column
                    prop="shangnian_xiaoshou_e_zengzhang_e"
                    min-width="180"
                    v-if="!yincang"
                    label="上年销售额增长额">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_xiaoshou_e_zengzhang_e" placeholder="请填写">
                        {{zhanshi==1?scope.row.shangnian_xiaoshou_e_zengzhang_e:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="shangnian_xiaoshou_zengzhang_lv"
                    min-width="180"
                    v-if="!yincang"
                    label="上年销售增长率">
                    <!-- <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_xiaoshou_zengzhang_lv" @keyup="shangnian_zengzheng(scope.row)" placeholder="请输入(1-100)数字">
                        {{zhanshi==1?scope.row.shangnian_xiaoshou_zengzhang_lv:''}}
                    </template> -->
                </el-table-column>
                <el-table-column
                    prop="shangnian_xiaoshou_shuliang"
                    min-width="180"
                    v-if="!yincang"
                    label="上年销售数量">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_xiaoshou_shuliang" placeholder="请填写">
                        {{zhanshi==1?scope.row.shangnian_xiaoshou_shuliang:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="shangnian_ke_danjia"
                    min-width="180"
                    v-if="!yincang"
                    label="上年客单价">
                </el-table-column>
                <el-table-column
                    prop="shangnian_xiaoshou_jiashu"
                    min-width="180"
                    v-if="!yincang"
                    label="上年销售家数">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_xiaoshou_jiashu" placeholder="请填写">
                        {{zhanshi==1?scope.row.shangnian_xiaoshou_jiashu:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bennian_mubiao_xiaoshou_e"
                    min-width="180"
                    :label="'本年目标销售额('+zhanshi_time+')'">
                    <template slot-scope="scope">
                        <input v-show="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.bennian_mubiao_xiaoshou_e" placeholder="请填写">
                        {{zhanshi==1?scope.row.bennian_mubiao_xiaoshou_e:''}}
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="bennian_mubiao_xiaoshou_e_yuce_wancheng_lv"
                    min-width="180"
                    label="本年目标销售额预测完成率">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv" @keyup="bennian_mubiao_xiaolv(scope.row)" placeholder="请输入(1-100)数字">
                        {{zhanshi==1?scope.row.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bennian_xiaoshou_yuce_wancheng_e"
                    min-width="180"
                    label="本年销售预测完成额">
                </el-table-column> -->
                <el-table-column
                    prop="bennian_yuce_xiaoshou_zengzhang_e"
                    min-width="180"
                    v-if="!yincang"
                    label="本年预测销售增长额">
                </el-table-column>
                <el-table-column
                    prop="bennian_yuce_xiaoshou_e_zengzhang_lv"
                    min-width="180"
                    v-if="!yincang"
                    label="本年预测销售额增长率">
                </el-table-column>
                <el-table-column
                    prop="yu_shangnian_zengzhang_e_duibi"
                    min-width="180"
                    v-if="!yincang"
                    label="与上年增长额对比">
                </el-table-column>
                <el-table-column
                    prop="yu_shangnian_zengzhang_lv_duibi"
                    min-width="180"
                    v-if="!yincang"
                    label="与上年增长率对比">
                </el-table-column>
                <el-table-column
                    prop="yuji_xiaoshou_shuliang"
                    min-width="180"
                    label="预计销售数量">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.yuji_xiaoshou_shuliang" placeholder="请填写">
                        {{zhanshi==1?scope.row.yuji_xiaoshou_shuliang:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="ke_danjia_mubiao"
                    min-width="180"
                    label="客单价目标">
                    <template slot-scope="scope">
                        <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.ke_danjia_mubiao" placeholder="请填写">
                        {{zhanshi==1?scope.row.ke_danjia_mubiao:''}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="yuji_xiaoshou_jiashu"
                    min-width="180"
                    label="预计销售家数">
                </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="tianjia_chanpin" v-if="dianji_bianji_chanpin">
            <div class="neirong">
                <p class="neirong_tit">编辑产品</p>
                <div class="neirong_con">
                    <div class="neirong_con_left">
                        <p class="neirong_con_tit">
                            选择：
                        </p>
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="filterText">
                        <!-- <el-tree :data="tree" default-expand-all :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
                        
                            <!--  -->
                        <el-tree
                            :data="tree"
                            show-checkbox
                            default-expand-all
                            ref="tree"
                            node-key='id'
                            highlight-current
                            :props="defaultProps"
                            :filter-node-method="filterNode"
                            style="background:#D8E6F7"
                            check-strictly
                            @check='fuxuan'
                            >
                            <span class="custom-tree-node" slot-scope="{ node,data}">
                                <span v-if="data.children!=undefined"><img src="../../assets/huang_wenjian.png" alt=""></span>
                                <span>{{ node.label }}</span>
                            </span>
                        </el-tree>
                    </div>
                    <div class="neirong_con_right">
                        <p class="neirong_con_tit">
                            已选：
                        </p>
                        <ul>
                            <li v-for='(i,index) in youce_xuanzhong_list' :key='index'>{{i.prod_code!=undefined?i.prod_code+'——':''}}{{i.label}}
                                <!-- <img src="../../assets/guanbi_zhu.png" @click="yichu_xuanzhong(i)"> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="neirong_con_foot">
                    <span @click="bianji_chanpin_queding">确定</span>
                    <span @click="bianji_chanpin_quxiao">取消</span>
                </div>
            </div>
        </div>
        <div class="tianjia_chanpin" v-if="dianji_xinzeng_chanpin">
            <div class="neirong">
                <p class="neirong_tit">编辑产品</p>
                <div class="neirong_con">
                    <div class="neirong_con_left">
                        <p class="neirong_con_tit">
                            选择：
                        </p>
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="filterText">
                        <!-- <el-tree :data="tree" default-expand-all :props="defaultProps" @node-click="handleNodeClick"></el-tree> -->
                        
                            <!--  -->
                        <el-tree
                            :data="tree"
                            show-checkbox
                            default-expand-all
                            ref="tree"
                            node-key='prod_id'
                            highlight-current
                            :props="defaultProps"
                            :filter-node-method="filterNode"
                            style="background:#D8E6F7"
                            check-strictly
                            @check='fuxuan'
                            >
                            <span class="custom-tree-node" slot-scope="{ node,data}">
                                <span v-if="data.children!=undefined"><img src="../../assets/huang_wenjian.png" alt=""></span>
                                <span>{{ node.label }}</span>
                            </span>
                        </el-tree>
                    </div>
                    <div class="neirong_con_right">
                        <p class="neirong_con_tit">
                            已选：
                        </p>
                        <ul>
                            <li v-for='(i,index) in youce_xuanzhong_list' :key='index'>{{i.prod_code!=undefined?i.prod_code+'——':''}}{{i.label}}
                                <!-- <img src="../../assets/guanbi_zhu.png" @click="yichu_xuanzhong(i)"> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="neirong_con_foot">
                    <span @click="xinzeng_chanpin_queding">确定</span>
                    <span @click="xinzeng_chanpin_quxiao">取消</span>
                </div>
            </div>
        </div>
        <div class="tiaozheng_mubiao" v-if="tiaozheng_list" @click="tiaozheng_list=false">
            <div class="tiaozheng_box" @click.stop>
                <div class="tiaozheng_con">
                    <p class="tiaozheng_tit">调整目标</p>
                    <div class="tiaozheng_tit_right">
                        <!-- <p>筛选</p> -->
                        <p>单位 : 元</p>
                    </div>
                    <div class="table">
                        <span @click="xinzeng_chanpin">新增产品</span>
                        <div>
                            <p @click="tiaozheng_baocun">保存</p>
                            <p @click="tiaozheng_list=false" style="margin-right:0;">关闭</p>
                        </div>
                    </div>
                    <div class="table_con">
                        <!-- :summary-method="getSummaries"
                            :show-summary="showSum" -->
                        <el-table
                            :data="zhanshi_tiaozheng_list"
                            tooltip-effect="dark"
                            border
                            max-height="600"
                            :cell-style="cellStyle"
                            :header-cell-style="headerCellStyle"
                            :summary-method="tiaozheng_heji"
                            :show-summary="tiaozheng_num">
                        <el-table-column
                            label="产品编号">
                            <template slot-scope="scope">
                                {{ scope.row.chanpin_bianhao!=undefined?scope.row.chanpin_bianhao:'' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="chanpin"
                            label="产品">
                        </el-table-column>
                        <!-- <el-table-column
                            prop="shangnian_xiaoshou_shuliang"
                            label="上年销售数量">
                            <template slot-scope="scope">
                                <input v-if="zhanshi==0" class="table_box_input" type="number" v-model="scope.row.shangnian_xiaoshou_shuliang" placeholder="请填写">
                                {{zhanshi==1?scope.row.shangnian_xiaoshou_shuliang:''}}
                            </template>
                        </el-table-column> -->
                        <el-table-column
                            prop="bennian_mubiao_xiaoshou_e"
                            label="本年目标销售额">
                            <template slot-scope="scope">
                                {{scope.row.bennian_mubiao_xiaoshou_e}}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="bennian_mubiao_xiaoshou_e_tiaozheng"
                            label="本年目标销售额调整">
                            <template slot-scope="scope">
                                <input class="table_box_input" type="number" v-model="scope.row.bennian_mubiao_xiaoshou_e_tiaozheng" placeholder="请填写">
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="tiaozheng_hou_bennian_mubiao_xiaoshou_e"
                            label="调整后本年目标销售额">
                            <template slot-scope="scope">
                                {{scope.row.tiaozheng_hou_bennian_mubiao_xiaoshou_e}}
                            </template>
                        </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <div class="lishi_chaxun" v-if="lishi_yemian">
            <div class="lishi_chaxun_box">
                <div class="lishi_chaxun_con">
                    <p class="lishi_chaxun_tit">历史目标调整数据</p>
                    <div class="lishi_chaxun_tit_right">
                        <!-- <p>筛选</p> -->
                        <p>单位 : 元</p>
                    </div>
                    <div class="table">
                        <p style="margin-right:0;" @click="lishi_yemian=false">关闭</p>
                    </div>
                    <div class="table_con">
                        <el-table :data="lishi_list"
                            border
                            :cell-style="cellStyle"
                            :header-cell-style="headerCellStyle"
                            :summary-method="getSummaries"
                            show-summary
                            align="left">
                            <el-table-column v-for="(item, index) in lishi_tit"
                                :key="index"
                                :prop="lishi_tit[index].con"
                                :label="item.name">
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <tuer ref="mubiao_tu2"></tuer> 
    </div>
</template>

<script>
import tuer from '../Figures/Figuretwo'
import { query_prod_tree_sale_rule, query_as_prod_aim_log, update_as_prod_aim, insert_as_prod_aim, query_as_aim, query_as_year_list, query_ent_prod_list_tree } from '../../api/api.js'
export default {
  name: 'tabletwo',
  data () {
    return {
      yincang: true, // 先写死一个值   还没有进行判断
      time: '',
      cunzai_shijian_list: [],
      danwei: false,
      tree: '',
      defaultProps: {
        children: 'children',
        label: 'label',
        id: 'id'
      },
      dianji_bianji_chanpin: false,
      nian_sj: '',
      nianfen_xianshi_yincang: false,
      filterText: '', // 输入文字
      youce_xuanzhong_list: [], // 编辑产品右侧选中的产品
      zhanshi_xuanze_list: [], // 展示的列表
      showSum: true,
      shujuku_cunzai_shijian: '',
      zhanshi: '',
      zhanshi_xuanzhong_list: '',
      tiaozheng_mubiao_list: [],
      tiaozheng_list: false,
      zhanshi_tiaozheng_list: [],
      tiaozheng_num: true,
      lishi_tit: [],
      lishi_list: [],
      lishi_yemian: false,
      shifou_baocun: '',

      dianji_xinzeng_chanpin: false,
      zhanshi_time: ''
    }
  },
  created () {
    this.jichu()
    this.panduan_baocun()
  },
  mounted () {
    this.$refs.mubiao_tu2.biaoge_dakai()
  },
  components: { tuer },
  watch: {
    //   监听搜索的值
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    // 监听框里的值
    zhanshi_xuanze_list: {
      handler (newValue, oldValue) {
        console.log(this.$refs.multipleTable)
        this.zidong_shangnian_mubiao_wancheng_lv()
        this.zidong_shangnian_xiaoshou_zengzhang_lv()
        this.zidong_shangnian_ke_danjia()
        this.zidong_bennian_yuce_xiaoshou_zengzhang_e()
        // this.zidong_bennian_xiaoshou_yuce_wancheng_e()
        this.zidong_bennian_yuce_xiaoshou_e_zengzhang_lv()
        this.zidong_yu_shangnian_zengzhang_e_duibi()
        this.zidong_yu_shangnian_zengzhang_lv_duibi()
        this.zidong_yuce_xiaoshou_jiashu()
        this.panduan_baocun()
        this.showSum = false
        this.$nextTick()
          .then(() => {
            this.showSum = true
          })
        this.cunchu()
      },
      deep: true
    },
    // 监听调整里的值
    zhanshi_tiaozheng_list: {
      handler (newValue, oldValue) {
        this.zidong_tiaozheng_hou_jine()
        this.tiaozheng_num = false
        this.$nextTick()
          .then(() => {
            this.tiaozheng_num = true
          })
        this.cunchu()
      },
      deep: true
    }
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '2'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10237) {
          const data = JSON.parse(res.data.body.data)
          this.shujuku_cunzai_shijian = data
          if (data.length == 0) {
            // this.chaxun()
            this.nianfen_xianshi_yincang = true,
            this.zhanshi = '0'
          } else {
            this.nianfen_xianshi_yincang = false
            data.forEach(item => {
              if(item == new Date().getFullYear()) {
                this.time = item + '年'
              } else {
                this.time = ''
              }
            })
            // if (localStorage.getItem("biaoer_shijian") != null&&localStorage.getItem("biaoer_shijian") != undefined&&localStorage.getItem("biaoer_shijian")!= ''){
            //     let date=JSON.parse(localStorage.getItem("biaoer_shijian"))
            //     for(let i=0;i<date.length;i++){
            //         if(date[i].time==data[data.length-1]+'年'){
            //             console.log(date)
            //             this.time=date[i].time
            //             this.zhanshi_xuanze_list=date[i].list
            //             this.zhanshi=date[i].zhanshi
            //             this.cunzai_shijian_list=date
            //             this.diyiwei='1'
            //         }
            //     }
            // }else{
            query_as_aim({
              data: {
                ent_id: this.$ent_id(),
                year: this.time.slice(0, 4)
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10235) {
                const list = JSON.parse(res.data.body.data)
                console.log(list)
                this.zhanshi_time = JSON.parse(res.data.body.date) != null ? JSON.parse(res.data.body.date) : ''
                for (let i = 0; i < data.length; i++) {
                  this.cunzai_shijian_list.push({
                    time: data[i] + '年',
                    zhanshi: '1'
                  })
                }
                for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
                  console.log(this.cunzai_shijian_list[i])
                  if (this.cunzai_shijian_list[i].time.slice(0, 4) == this.time.slice(0, 4)) {
                    const zhi = this.cunzai_shijian_list
                    zhi[i].list = []
                    for (let a = 0; a < list.length; a++) {
                      let aa = ''
                      let bb = ''
                      let cc = ''
                      let dd = ''
                      let ee = ''
                      let ff = ''
                      if (list[a].amt_sale_aim_ly != null) {
                        aa = list[a].amt_sale_aim_ly
                      } else {
                        aa = ''
                      }
                      if (list[a].amt_sale_ly != undefined) {
                        bb = list[a].amt_sale_ly
                      } else {
                        bb = ''
                      }
                      if (list[a].amt_growth_sale_ly != undefined) {
                        cc = list[a].amt_growth_sale_ly
                      } else {
                        cc = ''
                      }
                      if (list[a].rate_growth_sale_ly != undefined) {
                        dd = list[a].rate_growth_sale_ly
                      } else {
                        dd = ''
                      }
                      if (list[a].num_sale_ly != undefined) {
                        ee = list[a].num_sale_ly
                      } else {
                        ee = ''
                      }
                      if (list[a].num_cust_ly != undefined) {
                        ff = list[a].num_cust_ly
                      } else {
                        ff = ''
                      }
                      console.log(list[a])
                      zhi[i].list.push({
                        chanpin_bianhao: list[a].prod_code != undefined ? list[a].prod_code : '',
                        prod_id: list[a].prod_id,
                        chanpin: list[a].prod_name,
                        bennian_mubiao_xiaoshou_e: list[a].amt_sale_aim,
                        // bennian_mubiao_xiaoshou_e_yuce_wancheng_lv:list[a].rate_complete_aim_est,
                        // bennian_xiaoshou_yuce_wancheng_e:'',
                        yuji_xiaoshou_shuliang: list[a].num_sale_est,
                        ke_danjia_mubiao: list[a].pct_est,
                        yuji_xiaoshou_jiashu: '',
                        shangnian_mubiao_xiaoshou_e: aa,
                        shangnian_xiaoshou_e: bb,
                        shangnian_mubiao_xiaoshou_wancheng_lv: '',
                        shangnian_xiaoshou_e_zengzhang_e: cc,
                        shangnian_xiaoshou_zengzhang_lv: dd,
                        shangnian_xiaoshou_shuliang: ee,
                        shangnian_ke_danjia: '',
                        shangnian_xiaoshou_jiashu: ff,
                        bennian_yuce_xiaoshou_zengzhang_e: '',
                        bennian_yuce_xiaoshou_e_zengzhang_lv: '',
                        yu_shangnian_zengzhang_e_duibi: '',
                        yu_shangnian_zengzhang_lv_duibi: '',
                        type: list[a].prod_type == 1 ? '2' : '1'
                      })
                    }
                    zhi[i].diyiwei = '1'
                    zhi[i].zhanshi = 1
                    this.zhanshi = zhi[i].zhanshi
                    this.zhanshi_xuanze_list = zhi[i].list
                    console.log(this.zhanshi_xuanze_list)
                  }
                }
                console.log(this.cunzai_shijian_list)
              } else if (res.data.code == 10236) {}
            })
            // }
          }
        } else if (res.data.code == 10238) {}
      })
    },
    // 判断是否能点保存
    panduan_baocun () {
      if (this.zhanshi_xuanze_list != []) {
        let shifou = ''
        const list = this.zhanshi_xuanze_list
        if (this.zhanshi == 1) {
          shifou = true
        } else {
          const aa = []
          for (let i = 0; i < list.length; i++) {
            // &&list[i].bennian_mubiao_xiaoshou_e_yuce_wancheng_lv!=''
            if (list[i].bennian_mubiao_xiaoshou_e != '' && list[i].yuji_xiaoshou_shuliang != '' && list[i].ke_danjia_mubiao != '' && list[i].yuji_xiaoshou_jiashu != '') {
              aa.push(i)
            }
          }
          if (aa.length == 0) {
            shifou = true
          } else {
            shifou = false
          }
        }
        this.shifou_baocun = shifou
      } else {
        this.shifou_baocun = true
      }
    },
    // 本地是否有时间戳
    chaxun () {
      if (localStorage.getItem('biaoer_shijian') != null && localStorage.getItem('biaoer_shijian') != undefined && localStorage.getItem('biaoer_shijian') != '') {
        // this.left_index = JSON.parse(sessionStorage.getItem("pc_mlbb_mubiao_biao"));
        this.nianfen_xianshi_yincang = false
        const date = JSON.parse(localStorage.getItem('biaoer_shijian'))
        for (let i = 0; i < date.length; i++) {
          // this.zhanshi_xuanze_list.push(date[i])
          if (date[i].diyiwei == 1) {
            this.time = date[i].time
            this.cunzai_shijian_list = date
            this.zhanshi_xuanze_list = date[i].list
            this.zhanshi = date[i].zhanshi
            this.diyiwei = date[i].diyiwei
          }
        }
      } else {
        this.nianfen_xianshi_yincang = true
      }
    },
    // 公用存储
    cunchu () {
      if (this.cunzai_shijian_list != '') {
        for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
          if (this.cunzai_shijian_list[i].time == this.time) {
            this.cunzai_shijian_list[i].list = this.zhanshi_xuanze_list
            this.cunzai_shijian_list[i].diyiwei = this.diyiwei
            this.cunzai_shijian_list[i].zhanshi = this.zhanshi
          } else {
            this.cunzai_shijian_list[i].diyiwei = '0'
          }
        }
        localStorage.setItem('biaoer_shijian', JSON.stringify(this.cunzai_shijian_list))
      } else {
        this.cunzai_shijian_list.push({
          time: this.time,
          diyiwei: '1',
          list: this.zhanshi_xuanze_list,
          zhanshi: this.zhanshi
        })
        localStorage.setItem('biaoer_shijian', JSON.stringify(this.cunzai_shijian_list))
      }
    },
    //   选择隐藏或显示
    yincang_xuanze () {

    },
    //   选择年份
    xuanze_nianfen_biao () {
      console.log(this.time)
      for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
        if (this.cunzai_shijian_list[i].time == this.time) {
          if (this.cunzai_shijian_list[i].list == undefined) {
            console.log(1111)
            query_as_aim({
              data: {
                ent_id: this.$ent_id(),
                year: this.time.slice(0, 4)
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10235) {
                const list = JSON.parse(res.data.body.data)
                console.log(list)
                for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
                  console.log(this.cunzai_shijian_list[i])
                  if (this.cunzai_shijian_list[i].time.slice(0, 4) == this.time.slice(0, 4)) {
                    const zhi = this.cunzai_shijian_list
                    zhi[i].list = []
                    // time:this.time,
                    // zhanshi:'1'
                    for (let a = 0; a < list.length; a++) {
                      let aa = ''
                      let bb = ''
                      let cc = ''
                      let dd = ''
                      let ee = ''
                      let ff = ''
                      if (list[i].amt_sale_aim_ly != undefined) {
                        aa = list[i].amt_sale_aim_ly
                      }
                      if (list[i].amt_sale_ly != undefined) {
                        bb = list[i].amt_sale_ly
                      }
                      if (list[i].amt_growth_sale_ly != undefined) {
                        cc = list[i].amt_growth_sale_ly
                      }
                      if (list[i].rate_growth_sale_ly != undefined) {
                        dd = list[i].rate_growth_sale_ly
                      }
                      if (list[i].num_sale_ly != undefined) {
                        ee = list[i].num_sale_ly
                      }
                      if (list[i].num_cust_ly != undefined) {
                        ff = list[i].num_cust_ly
                      }
                      zhi[i].list.push({
                        chanpin_bianhao: list[a].prod_code != undefined ? list[a].prod_code : '',
                        prod_id: list[a].prod_id,
                        chanpin: list[a].prod_name,
                        bennian_mubiao_xiaoshou_e: list[a].amt_sale_aim,
                        // bennian_mubiao_xiaoshou_e_yuce_wancheng_lv:list[a].rate_complete_aim_est,
                        // bennian_xiaoshou_yuce_wancheng_e:'',
                        yuji_xiaoshou_shuliang: list[a].num_sale_est,
                        ke_danjia_mubiao: list[a].pct_est,
                        yuji_xiaoshou_jiashu: '',
                        shangnian_mubiao_xiaoshou_e: aa,
                        shangnian_xiaoshou_e: bb,
                        shangnian_mubiao_xiaoshou_wancheng_lv: '',
                        shangnian_xiaoshou_e_zengzhang_e: cc,
                        shangnian_xiaoshou_zengzhang_lv: dd,
                        shangnian_xiaoshou_shuliang: ee,
                        shangnian_ke_danjia: '',
                        shangnian_xiaoshou_jiashu: ff,
                        bennian_yuce_xiaoshou_zengzhang_e: '',
                        bennian_yuce_xiaoshou_e_zengzhang_lv: '',
                        yu_shangnian_zengzhang_e_duibi: '',
                        yu_shangnian_zengzhang_lv_duibi: ''
                      })
                    }
                    zhi[i].diyiwei = '1'
                    zhi[i].zhanshi = 1
                    this.zhanshi = zhi[i].zhanshi
                    this.zhanshi_xuanze_list = zhi[i].list
                    console.log(this.zhanshi_xuanze_list)
                  }
                }
                console.log(this.cunzai_shijian_list)
              } else if (res.data.code == 10236) {}
            })
          } else {
            this.time = this.cunzai_shijian_list[i].time
            this.zhanshi_xuanze_list = this.cunzai_shijian_list[i].list
            this.diyiwei = '1'
            this.zhanshi = this.cunzai_shijian_list[i].zhanshi
            this.cunzai_shijian_list[i].diyiwei = '1'
            console.log(this.cunzai_shijian_list[i].zhanshi)
          }
          this.panduan_baocun()
        } else {
          this.cunzai_shijian_list[i].diyiwei = '0'
        }
      }
    },
    //   单位选择
    danwei_xuanze () {
    },
    bianji_chanpin () {
      if (this.zhanshi == 1) {
        this.$message({
          message: '您已保存表格',
          type: 'warning'
        })
      } else {
        // query_prod_tree_sale_rule({data:{
        //     ent_id:this.$ent_id()
        // }}).then(res=>{
        //     console.log(res)
        //     if(res.data.code==200){
        //         let date=JSON.parse(res.data.body.data)
        //         console.log(res)
        //     }else if(res.data.code==500){

        //     }
        // })
        query_ent_prod_list_tree({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.tree = date
            this.dianji_bianji_chanpin = true
          } else if (res.data.code == 201) {}
        })
      }
    },
    handleNodeClick (data) {
      console.log(data)
    },
    //    年份选择确定
    xuanze_nianfen_queding () {
      if (this.nian_sj != '') {
        const sj = this.nian_sj.getFullYear() + '年'
        if (this.shujuku_cunzai_shijian == '' && this.cunzai_shijian_list == '') {
          this.nianfen_xianshi_yincang = false
          this.time = sj
          this.cunzai_shijian_list.push({
            time: sj,
            diyiwei: '1',
            list: [],
            zhanshi: 0
          })
          this.nian_sj = ''
          this.zhanshi = 0
          // this.$store.commit('setTokenn','aaqaaaa')  //暂时用不到
          localStorage.setItem('biaoer_shijian', JSON.stringify(this.cunzai_shijian_list))
        } else {
          if (this.shujuku_cunzai_shijian != '') {
            let a = ''
            for (let i = 0; i < this.shujuku_cunzai_shijian.length; i++) {
              console.log(this.shujuku_cunzai_shijian[i])
              if (this.shujuku_cunzai_shijian[i] == sj.slice(0, 4)) {
                a = '2'
                this.$message({
                  message: '已存在当前年份',
                  type: 'warning'
                })
              }
            }
            if (a != 2) {
              this.nianfen_xianshi_yincang = false
              this.time = sj
              this.diyiwei = '1'
              this.cunzai_shijian_list.push({
                time: sj,
                diyiwei: '1',
                list: this.zhanshi_xuanze_list,
                zhanshi: 0
              })
              this.nian_sj = ''
              this.zhanshi = '0' 
              this.zhanshi_xuanze_list = []
              // this.$store.commit('setTokenn','aaqaaaa')  //暂时用不到
              // localStorage.setItem("biaoer_shijian", JSON.stringify(this.cunzai_shijian_list));
            }
          } else {
            if (this.cunzai_shijian_list != '') {
              let a = ''
              for (let i = 0; i < this.cunzai_shijian_list.length; i++) {
                if (this.cunzai_shijian_list[i].time == sj) {
                  this.$message({
                    message: '已存在当前年份',
                    type: 'warning'
                  })
                  a = '2'
                }
              }
              if (a != '2') {
                a = '1'
                this.nianfen_xianshi_yincang = false
                this.time = sj
                this.cunzai_shijian_list.push({
                  time: sj,
                  diyiwei: '1',
                  list: [],
                  zhanshi: 0
                })
                this.nian_sj = ''
                this.diyiwei = '1' 
                this.zhanshi = '0' 
                // this.$store.commit('setTokenn','aaqaaaa')  //暂时用不到
                localStorage.setItem('biaoer_shijian', JSON.stringify(this.cunzai_shijian_list))
                // this.chaxun()
              }
            }
          }
        }
      } else {
        this.$message({
          message: '请选择年份',
          type: 'warning'
        })
      }
    },
    //    年份选择取消
    xuanze_nianfen_quxiao () {
      if (this.shujuku_cunzai_shijian != '' || this.cunzai_shijian_list != '') {
        this.nianfen_xianshi_yincang = false
      } else {
        this.$router.push('/aspreackeven')
        sessionStorage.setItem('pc_mlbb_mubiao_biao', '-1')
      }
    },
    //    搜索方法
    filterNode (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 复选框点击
    fuxuan () {
      console.log(this.$refs.tree)
      const data = this.$refs.tree.getCheckedNodes()
      console.log(data)
      // console.log(i)
      // let youce_list=[]
      // for(let i=0;i<date.length;i++){
      //     if(date[i].children==undefined){
      //         youce_list.push(date[i])
      //     }
      // }
      // console.log(youce_list)
      this.youce_xuanzhong_list = data
    },
    // 移除选中
    yichu_xuanzhong (i) {
      console.log(i)
      for (let a = 0; a < this.youce_xuanzhong_list.length; a++) {
        if (i.id == this.youce_xuanzhong_list[a].id) {
          this.youce_xuanzhong_list.splice(a, 1)
        }
      }
      console.log(this.$refs.tree)
    },
    // 编辑产品点击确定
    bianji_chanpin_queding () {
      const list = []
      for (let i = 0; i < this.youce_xuanzhong_list.length; i++) {
        list.push({
          chanpin_bianhao: this.youce_xuanzhong_list[i].prod_code != undefined ? this.youce_xuanzhong_list[i].prod_code : '',
          prod_id: this.youce_xuanzhong_list[i].id,
          chanpin: this.youce_xuanzhong_list[i].label,
          shangnian_mubiao_xiaoshou_e: '',
          shangnian_xiaoshou_e: '',
          shangnian_mubiao_xiaoshou_wancheng_lv: '',
          shangnian_xiaoshou_e_zengzhang_e: '',
          shangnian_xiaoshou_zengzhang_lv: '',
          shangnian_xiaoshou_shuliang: '',
          shangnian_ke_danjia: '',
          shangnian_xiaoshou_jiashu: '',
          bennian_mubiao_xiaoshou_e: '',
          // bennian_mubiao_xiaoshou_e_yuce_wancheng_lv:'',
          // bennian_xiaoshou_yuce_wancheng_e:'',
          bennian_yuce_xiaoshou_zengzhang_e: '',
          bennian_yuce_xiaoshou_e_zengzhang_lv: '',
          yu_shangnian_zengzhang_e_duibi: '',
          yu_shangnian_zengzhang_lv_duibi: '',
          yuji_xiaoshou_shuliang: '',
          ke_danjia_mubiao: '',
          yuji_xiaoshou_jiashu: '',
          type: this.youce_xuanzhong_list[i].type
        })
      }
      console.log(list)
      this.zhanshi_xuanze_list = list
      this.youce_xuanzhong_list = []
      this.dianji_bianji_chanpin = false
    },
    xinzeng_chanpin_queding () {
      console.log(this.youce_xuanzhong_list)
      if (this.youce_xuanzhong_list.length != 0) {
        for (let i = 0; i < this.youce_xuanzhong_list.length; i++) {
          this.zhanshi_tiaozheng_list.push({
            chanpin_bianhao: this.youce_xuanzhong_list[i].prod_code != undefined ? this.youce_xuanzhong_list[i].prod_code : '',
            prod_id: this.youce_xuanzhong_list[i].id,
            chanpin: this.youce_xuanzhong_list[i].label,
            bennian_mubiao_xiaoshou_e: 0,
            bennian_mubiao_xiaoshou_e_tiaozheng: '',
            tiaozheng_hou_bennian_mubiao_xiaoshou_e: '',
            add_flag: '1',
            type: this.youce_xuanzhong_list[i].type
          })
        }
      }
      this.dianji_xinzeng_chanpin = false
    },
    // 编辑产品点击取消
    bianji_chanpin_quxiao () {
      this.youce_xuanzhong_list = []
      this.dianji_bianji_chanpin = false
    },
    xinzeng_chanpin_quxiao () {
      this.youce_xuanzhong_list = []
      this.dianji_xinzeng_chanpin = false
    },
    dianji_xuanzhong (val) {
      console.log(val)
      this.zhanshi_xuanzhong_list = val
    },
    // 点击进行删除
    dianji_shanchu () {
      if (this.zhanshi == 1) {
        this.$message({
          message: '该表已保存，禁止删除',
          type: 'warning'
        })
      } else if (this.zhanshi == 0) {
        if (this.zhanshi_xuanzhong_list == '') {
          this.$message({
            message: '请选中再删除',
            type: 'warning'
          })
        } else {
          for (let i = 0; i < this.zhanshi_xuanzhong_list.length; i++) {
            for (let a = 0; a < this.zhanshi_xuanze_list.length; a++) {
              if (this.zhanshi_xuanzhong_list[i].prod_id == this.zhanshi_xuanze_list[a].prod_id) {
                this.zhanshi_xuanze_list.splice(a, 1)
                // this.zhanshi_xuanzhong_list.splice(i,1)
              }
            }
          }
        }
      }
    },
    // 点击调整目标
    dianji_tiaozhengmubiao () {
      if (this.zhanshi == 1) {
        this.tiaozheng_list = true
        this.zhanshi_tiaozheng_list = []
        console.log(this.zhanshi_xuanze_list)
        if (this.zhanshi_xuanze_list != []) {
          for (let i = 0; i < this.zhanshi_xuanze_list.length; i++) {
            this.zhanshi_tiaozheng_list.push({
              chanpin_bianhao: this.zhanshi_xuanze_list[i].chanpin_bianhao.length != 0 ? this.zhanshi_xuanze_list[i].chanpin_bianhao : '',
              prod_id: this.zhanshi_xuanze_list[i].prod_id,
              chanpin: this.zhanshi_xuanze_list[i].chanpin,
              bennian_mubiao_xiaoshou_e: this.zhanshi_xuanze_list[i].bennian_mubiao_xiaoshou_e,
              bennian_mubiao_xiaoshou_e_tiaozheng: '',
              tiaozheng_hou_bennian_mubiao_xiaoshou_e: '',
              add_flag: '0',
              type: this.zhanshi_xuanze_list[i].type
            })
          }
        }
      } else {
        this.$message({
          message: '该表格还未进行保存',
          type: 'warning'
        })
      }
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      // console.log(row.column.label);
      if (row.column.label == '本年预测销售增长额') {
        return 'background:#FFEDDA;text-align:center;color:#1A2533;'
      }
      if (row.column.label == '本年预测销售额增长率') {
        return 'background:#FFEDDA;text-align:center;color:#1A2533;'
      }
      if (row.column.label != undefined) {
        if (row.column.label.indexOf('上年') != -1) {
          return 'background:#FFEDDA;text-align:center;color:#1A2533;'
        } else {
          return 'text-align:center;color:#1A2533;'
        }
      }
    },
    // color:#4C4A4D;font-size:0.12rem;
    cellStyle (row) {
      if (row.column.label != undefined) {
        if (row.column.label == '上年目标销售额完成率') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '上年销售增长率') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '调整后本年目标销售额') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '上年客单价') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '本年预测销售增长额') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '本年预测销售额增长率') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '与上年增长额对比') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '与上年增长率对比') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '本年销售预测完成额') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
        if (row.column.label == '预计销售家数') {
          return 'background:#E2EFDA;color:#4C4A4D;font-size:0.12rem;text-align:right;'
        } else {
          return 'color:#4C4A4D;font-size:0.12rem;text-align:right;'
        }
      }
    },
    // 合算
    getSummaries (param) {
      // console.log(param)
      const { columns, data } = param
      const sums = []
      var reg = /\d(?=(?:\d{3})+\b)/g
      let zhi = ''
      if (data != null) {
        columns.forEach((column, index) => {
          console.log(column)
          console.log(index)
          if (index === 0) {
            sums[index] = '合计'
            return
          }
          if (column.property != 'bennian_mubiao_xiaoshou_e_yuce_wancheng_lv' && column.property != 'shangnian_mubiao_xiaoshou_wancheng_lv' && column.property != 'bennian_yuce_xiaoshou_e_zengzhang_lv' && column.property != 'shangnian_xiaoshou_zengzhang_lv') {
            const values = data.map(item => Number(item[column.property]))
            console.log(values)
            if (!values.every(value => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                console.log(prev)
                console.log(curr)
                const value = Number(curr)
                if (!isNaN(value)) {
                  if (!isNaN(Number(prev)) && !isNaN(Number(curr))) {
                    zhi = (Number(prev) + Number(curr)).toFixed(2).replace(reg, '$&,')
                    return zhi
                  } else if (isNaN(Number(prev)) && isNaN(Number(curr))) {
                    zhi = (Number(prev.replace(/,/g, '')) + Number(curr.replace(/,/g, ''))).toFixed(2).replace(reg, '$&,')
                    return zhi
                  } else if (!isNaN(Number(prev)) && isNaN(Number(curr))) {
                    zhi = (Number(prev) + Number(curr.replace(/,/g, ''))).toFixed(2).replace(reg, '$&,')
                    return zhi
                  } else if (isNaN(Number(prev)) && !isNaN(Number(curr))) {
                    zhi = (Number(prev.replace(/,/g, '')) + Number(curr)).toFixed(2).replace(reg, '$&,')
                    return zhi
                  }
                } else {
                  if (!isNaN(Number(prev))) {
                    zhi = (Number(prev)).toFixed(2).replace(reg, '$&,')
                    return zhi
                  } else {
                    zhi = Number(prev.replace(/,/g, '')).toFixed(2).replace(reg, '$&,')
                    return zhi
                  }
                }
              }, 0)
              sums[index] += ''
            }
          } else
          //  if(column.property=='bennian_mubiao_xiaoshou_e_yuce_wancheng_lv'){
          //     var values = data.map(item => Number(item[column.property]));
          //     // console.log(values)
          //     let a=['1']
          //     if (!values.every(value => isNaN(value))) {
          //         sums[index] = a.reduce((prev, i) => {
          //             console.log(this.zhanshi_xuanze_list)
          //             let zhi=''
          //             let bennian_mubiao=''
          //             this.zhanshi_xuanze_list.forEach(item=>{
          //                 if(item.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv!=undefined){
          //                     zhi=Number(zhi)+(Number(item.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv)*Number(item.bennian_mubiao_xiaoshou_e)/100)
          //                 }
          //                 bennian_mubiao=Number(bennian_mubiao)+Number(item.bennian_mubiao_xiaoshou_e)
          //             })
          //             console.log(zhi)
          //             console.log(bennian_mubiao)
          //             console.log(Number(zhi)/Number(bennian_mubiao))
          //             let fanhui='0'
          //             if(Number(zhi)!=0&&Number(bennian_mubiao)!=0){
          //                 fanhui=this.$qianwei((Number(zhi)/Number(bennian_mubiao)*100).toFixed(2))
          //             }
          //             return fanhui
          //         }, 0);
          //         sums[index] += '';
          //     }
          // }else
          if (column.property == 'shangnian_mubiao_xiaoshou_wancheng_lv') {
            // console.log(values)
            const a = ['1']
            sums[index] = a.reduce((prev, i) => {
              console.log(this.zhanshi_xuanze_list)
              let sn_xiaoshou_e = ''
              let shangnian_mubiao_xiaoshou_e = ''
              this.zhanshi_xuanze_list.forEach(item => {
                if (item.shangnian_xiaoshou_e != undefined) {
                  sn_xiaoshou_e = Number(sn_xiaoshou_e) + Number(item.shangnian_xiaoshou_e)
                }
                if (item.shangnian_mubiao_xiaoshou_e != undefined) {
                  shangnian_mubiao_xiaoshou_e = Number(shangnian_mubiao_xiaoshou_e) + Number(item.shangnian_mubiao_xiaoshou_e)
                }
              })
              let fanhui = '0'
              if (Number(sn_xiaoshou_e) != 0 && Number(shangnian_mubiao_xiaoshou_e) != 0) {
                fanhui = this.$qianwei((Number(sn_xiaoshou_e) / Number(shangnian_mubiao_xiaoshou_e) * 100).toFixed(2))
              }
              return fanhui
            }, 0)
            sums[index] += ''
          } else if (column.property == 'bennian_yuce_xiaoshou_e_zengzhang_lv') {
            // console.log(values)
            const a = ['1']
            sums[index] = a.reduce((prev, i) => {
              console.log(this.zhanshi_xuanze_list)
              let sn_xiaoshou_e = ''
              let bennian_yuce_zengzhang_e = ''
              this.zhanshi_xuanze_list.forEach(item => {
                if (item.shangnian_xiaoshou_e != undefined) {
                  sn_xiaoshou_e = Number(sn_xiaoshou_e) + Number(item.shangnian_xiaoshou_e)
                }
                if (item.bennian_yuce_xiaoshou_zengzhang_e != undefined) {
                  bennian_yuce_zengzhang_e = Number(bennian_yuce_zengzhang_e) + Number(item.bennian_yuce_xiaoshou_zengzhang_e)
                }
              })
              let fanhui = '0'
              if (Number(bennian_yuce_zengzhang_e) != 0 && Number(sn_xiaoshou_e) != 0) {
                fanhui = this.$qianwei((Number(bennian_yuce_zengzhang_e) / Number(sn_xiaoshou_e) * 100).toFixed(2))
              }
              return fanhui
            }, 0)
            sums[index] += ''
          } else if (column.property == 'shangnian_xiaoshou_zengzhang_lv') {
            const a = ['1']
            sums[index] = a.reduce((prev, i) => {
              console.log(this.zhanshi_xuanze_list)
              let sn_xiaoshou_e_zengzhang_e = ''
              let sn_xiaoshou_e = ''
              this.zhanshi_xuanze_list.forEach(item => {
                if (item.shangnian_xiaoshou_e_zengzhang_e != undefined) {
                  sn_xiaoshou_e_zengzhang_e = Number(sn_xiaoshou_e_zengzhang_e) + Number(item.shangnian_xiaoshou_e_zengzhang_e)
                }
                if (item.shangnian_xiaoshou_e != undefined) {
                  sn_xiaoshou_e = Number(sn_xiaoshou_e) + Number(item.shangnian_xiaoshou_e)
                }
              })
              let fanhui = '0'
              if (Number(sn_xiaoshou_e_zengzhang_e) != 0 && Number(sn_xiaoshou_e_zengzhang_e) != 0) {
                if (sn_xiaoshou_e != sn_xiaoshou_e_zengzhang_e) {
                  fanhui = this.$qianwei((Number(sn_xiaoshou_e_zengzhang_e) / (Number(sn_xiaoshou_e) - Number(sn_xiaoshou_e_zengzhang_e)) * 100).toFixed(2))
                } else {
                  fanhui = 0.00
                }
              }
              return fanhui
            }, 0)
            sums[index] += ''
          }
                    
          // const values = data.map(item => {
          //     if(item[column.property]!=''&&item[column.property]!=undefined&&item[column.property]!=0){
          //         // console.log(this.$shuzi(item[column.property]))
          //         return this.$shuzi(item[column.property])
          //     }
          //     // 
          // });
          // if (!values.every(value => {
          //     return isNaN(value)
          //     })) {
          //     sums[index] = values.reduce((prev, curr) => {
          //         // const value = Number(curr);
          //         if(curr!=undefined){
          //             const value = Number(curr);
          //             if (!isNaN(value)) {
          //                 if(prev!=undefined&&curr!=undefined){
          //                     console.log(curr)
          //                     if(!isNaN(prev)&&!isNaN(curr)){
          //                         zhi=(Number(this.$shuzi(prev+'')) + Number(this.$shuzi(curr)))
          //                         console.log(this.$shuzi(prev+''))
          //                         console.log(prev)
          //                         return zhi
          //                     }
          //                 }
          //             } else if(prev!=undefined){
          //                 if(!isNaN(prev)){
          //                     console.log(prev)
          //                     zhi=this.$qianwei(Number(this.$shuzi(prev)).toFixed(2))
          //                     return zhi
          //                 }
          //             }
          //         }
          //     }, 0);
          //         sums[index] += '';
          // }
        })
        return sums
      }
    },
    // 合算-调整目标里面的
    tiaozheng_heji (param) {
      // console.log(param)
      const { columns, data } = param
      const sums = []
      var reg = /\d(?=(?:\d{3})+\b)/g
      let zhi = ''
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              if (!isNaN(Number(prev)) && !isNaN(Number(curr))) {
                zhi = (Number(prev) + Number(curr)).toFixed(2).replace(reg, '$&,')
                return zhi
              } else if (isNaN(Number(prev)) && isNaN(Number(curr))) {
                zhi = (Number(prev.replace(/,/g, '')) + Number(curr.replace(/,/g, ''))).toFixed(2).replace(reg, '$&,')
                return zhi
              } else if (!isNaN(Number(prev)) && isNaN(Number(curr))) {
                zhi = (Number(prev) + Number(curr.replace(/,/g, ''))).toFixed(2).replace(reg, '$&,')
                return zhi
              } else if (isNaN(Number(prev)) && !isNaN(Number(curr))) {
                zhi = (Number(prev.replace(/,/g, '')) + Number(curr)).toFixed(2).replace(reg, '$&,')
                return zhi
              }
            } else {
              if (!isNaN(Number(prev))) {
                zhi = (Number(prev)).toFixed(2).replace(reg, '$&,')
                return zhi
              } else {
                zhi = Number(prev.replace(/,/g, '')).toFixed(2).replace(reg, '$&,')
                return zhi
              }
            }
          }, 0)
          sums[index] += ''
        }
      })
      return sums
    },
    // 自动计算
    zidong_shangnian_mubiao_wancheng_lv () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].shangnian_xiaoshou_e != '' && date[i].shangnian_mubiao_xiaoshou_e != '') {
            date[i].shangnian_mubiao_xiaoshou_wancheng_lv = (date[i].shangnian_xiaoshou_e / date[i].shangnian_mubiao_xiaoshou_e * 100).toFixed(2)
          } else {
            date[i].shangnian_mubiao_xiaoshou_wancheng_lv = ''
          }
        }
      }
    },
    zidong_shangnian_xiaoshou_zengzhang_lv () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].shangnian_xiaoshou_e_zengzhang_e != '' && date[i].shangnian_xiaoshou_e != '') {
            if (date[i].shangnian_xiaoshou_e_zengzhang_e != date[i].shangnian_xiaoshou_e) {
              date[i].shangnian_xiaoshou_zengzhang_lv = (date[i].shangnian_xiaoshou_e_zengzhang_e / (date[i].shangnian_xiaoshou_e - date[i].shangnian_xiaoshou_e_zengzhang_e) * 100).toFixed(2)
            } else {
              date[i].shangnian_xiaoshou_zengzhang_lv = 0.00
            }
          } else {
            date[i].shangnian_xiaoshou_zengzhang_lv = ''
          }
        }
      }
    },
    zidong_shangnian_ke_danjia () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].shangnian_xiaoshou_e != '' && date[i].shangnian_xiaoshou_jiashu != '') {
            date[i].shangnian_ke_danjia = (date[i].shangnian_xiaoshou_e / date[i].shangnian_xiaoshou_jiashu).toFixed(2)
          } else {
            date[i].shangnian_ke_danjia = ''
          }
        }
      }
    },
    zidong_bennian_yuce_xiaoshou_zengzhang_e () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          // &&date[i].bennian_mubiao_xiaoshou_e_yuce_wancheng_lv!=''
          if (date[i].shangnian_xiaoshou_e != '' && date[i].bennian_mubiao_xiaoshou_e != '') {
            date[i].bennian_yuce_xiaoshou_zengzhang_e = (date[i].bennian_mubiao_xiaoshou_e - date[i].shangnian_xiaoshou_e).toFixed(2)
          } else {
            date[i].bennian_yuce_xiaoshou_zengzhang_e = ''
          }
        }
      }
    },
    // zidong_bennian_xiaoshou_yuce_wancheng_e(){
    //     let date=this.zhanshi_xuanze_list
    //     if(date!=[]){
    //         for(let i=0;i<date.length;i++){
    //             if(date[i].bennian_mubiao_xiaoshou_e!=''&&date[i].bennian_mubiao_xiaoshou_e_yuce_wancheng_lv!=''){
    //                 date[i].bennian_xiaoshou_yuce_wancheng_e=(date[i].bennian_mubiao_xiaoshou_e*date[i].bennian_mubiao_xiaoshou_e_yuce_wancheng_lv/100).toFixed(2)
    //             }else{
    //                 date[i].bennian_xiaoshou_yuce_wancheng_e=''
    //             }
    //         }
    //     }
    // },
    zidong_bennian_yuce_xiaoshou_e_zengzhang_lv () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].shangnian_xiaoshou_e != '' && date[i].bennian_yuce_xiaoshou_zengzhang_e != '') {
            if (!isNaN(Number(date[i].bennian_yuce_xiaoshou_zengzhang_e))) {
              date[i].bennian_yuce_xiaoshou_e_zengzhang_lv = (date[i].bennian_yuce_xiaoshou_zengzhang_e / date[i].shangnian_xiaoshou_e * 100).toFixed(2)
            } else {
              date[i].bennian_yuce_xiaoshou_e_zengzhang_lv = (Number(date[i].bennian_yuce_xiaoshou_zengzhang_e.replace(/,/g, '')) / date[i].shangnian_xiaoshou_e * 100).toFixed(2)
            }
          } else {
            date[i].bennian_yuce_xiaoshou_e_zengzhang_lv = ''
          }
        }
      }
    },
    zidong_yu_shangnian_zengzhang_e_duibi () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].shangnian_xiaoshou_e_zengzhang_e != '' && date[i].bennian_yuce_xiaoshou_zengzhang_e != '') {
            if (!isNaN(Number(date[i].bennian_yuce_xiaoshou_zengzhang_e))) {
              date[i].yu_shangnian_zengzhang_e_duibi = (date[i].bennian_yuce_xiaoshou_zengzhang_e - date[i].shangnian_xiaoshou_e_zengzhang_e).toFixed(2)
            } else {
              date[i].yu_shangnian_zengzhang_e_duibi = (Number(date[i].bennian_yuce_xiaoshou_zengzhang_e.replace(/,/g, '')) - date[i].shangnian_xiaoshou_e_zengzhang_e).toFixed(2)
            }
          } else {
            date[i].yu_shangnian_zengzhang_e_duibi = ''
          }
        }
      }
    },
    zidong_yu_shangnian_zengzhang_lv_duibi () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].shangnian_xiaoshou_zengzhang_lv != '' && date[i].bennian_yuce_xiaoshou_e_zengzhang_lv != '') {
            if (!isNaN(Number(date[i].bennian_yuce_xiaoshou_e_zengzhang_lv))) {
              date[i].yu_shangnian_zengzhang_lv_duibi = (date[i].bennian_yuce_xiaoshou_e_zengzhang_lv - date[i].shangnian_xiaoshou_zengzhang_lv).toFixed(2)
            } else {
              date[i].yu_shangnian_zengzhang_lv_duibi = (Number(date[i].bennian_yuce_xiaoshou_e_zengzhang_lv.replace(/,/g, '')) - date[i].shangnian_xiaoshou_zengzhang_lv).toFixed(2)
            }
          } else {
            date[i].yu_shangnian_zengzhang_lv_duibi = ''
          }
        }
      }
    },
    zidong_yuce_xiaoshou_jiashu () {
      const date = this.zhanshi_xuanze_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          console.log(date[i].bennian_mubiao_xiaoshou_e)
          console.log(date[i].ke_danjia_mubiao)
          if (date[i].bennian_mubiao_xiaoshou_e != '' && date[i].ke_danjia_mubiao != '' && date[i].ke_danjia_mubiao != undefined) {
            date[i].yuji_xiaoshou_jiashu = (date[i].bennian_mubiao_xiaoshou_e / date[i].ke_danjia_mubiao).toFixed(0)
          } else {
            date[i].yuji_xiaoshou_jiashu = ''
          }
        }
      }
    },
    zidong_tiaozheng_hou_jine () {
      const date = this.zhanshi_tiaozheng_list
      if (date != []) {
        for (let i = 0; i < date.length; i++) {
          if (date[i].bennian_mubiao_xiaoshou_e_tiaozheng != '') {
            date[i].tiaozheng_hou_bennian_mubiao_xiaoshou_e = Number(date[i].bennian_mubiao_xiaoshou_e) + Number(date[i].bennian_mubiao_xiaoshou_e_tiaozheng)
          } else {
            date[i].tiaozheng_hou_bennian_mubiao_xiaoshou_e = date[i].bennian_mubiao_xiaoshou_e
          }
        }
      }
    },
    // 展示表格保存
    dianji_baocun () {
      if (this.shifou_baocun) {
        this.$message({
          message: '您已保存过或未填写完信息',
          type: 'warning'
        }) 
      } else {
        if (this.zhanshi_xuanze_list != '') {
          const list = this.zhanshi_xuanze_list
          const list2 = []
          for (let i = 0; i < list.length; i++) {
            console.log(list[i])
            // &&list[i].bennian_mubiao_xiaoshou_e_yuce_wancheng_lv!=''
            if (list[i].bennian_mubiao_xiaoshou_e != '' && list[i].yuji_xiaoshou_shuliang != '' && list[i].ke_danjia_mubiao != '' && list[i].yuji_xiaoshou_jiashu != '') {
              const a = list[i].shangnian_mubiao_xiaoshou_e
              const b = list[i].shangnian_xiaoshou_e
              const c = list[i].shangnian_xiaoshou_e_zengzhang_e
              const d = list[i].shangnian_ke_danjia
              const e = list[i].bennian_mubiao_xiaoshou_e
              const f = list[i].bennian_yuce_xiaoshou_zengzhang_e
              const g = list[i].ke_danjia_mubiao
              // if(this.danwei){
              //     if(a!=''){
              //         a=Number(a)*10000
              //     }
              //     if(b!=''){
              //         b=Number(b)*10000
              //     }
              //     if(c!=''){
              //         c=Number(c)*10000
              //     }
              //     if(d!=''){
              //         d=Number(d)*10000
              //     }
              //     if(e!=''){
              //         e=Number(e)*10000
              //     }
              //     if(f!=''){
              //         f=Number(f)*10000
              //     }
              //     if(g!=''){
              //         g=Number(g)*10000
              //     }
              // }
              list2.push({
                prod_code: list[i].chanpin_bianhao ? list[i].chanpin_bianhao : null,
                prod_id: list[i].prod_id,
                prod_name: list[i].chanpin,
                amt_sale_aim_ly: a,
                amt_sale_ly: b,
                rate_complete_aim_ly: list[i].shangnian_mubiao_xiaoshou_wancheng_lv,
                amt_growth_sale_ly: c,
                rate_growth_sale_ly: list[i].shangnian_xiaoshou_zengzhang_lv,
                num_sale_ly: list[i].shangnian_xiaoshou_shuliang,
                pct_ly: d,
                num_cust_ly: list[i].shangnian_xiaoshou_jiashu,
                amt_sale_aim: e,
                // rate_complete_aim_est:list[i].bennian_mubiao_xiaoshou_e_yuce_wancheng_lv,
                // amt_complete_aim_est:list[i].bennian_xiaoshou_yuce_wancheng_e,//未定义名称
                amt_growth_sale_aim_est: f,
                rate_growth_sale_aim_est: list[i].bennian_yuce_xiaoshou_e_zengzhang_lv,
                amt_growth_compare_ly: list[i].yu_shangnian_zengzhang_e_duibi,
                rate_growth_compare_ly: list[i].yu_shangnian_zengzhang_lv_duibi,
                num_sale_est: list[i].yuji_xiaoshou_shuliang,
                pct_est: g,
                num_cust_est: list[i].yuji_xiaoshou_jiashu,
                prod_type: list[i].type == 1 ? '2' : '1'
              })
            }
          }
          if (list2 != []) {
            console.log(list2)
            insert_as_prod_aim({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                year: this.time.slice(0, 4),
                list: list2
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10231) {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                })
                this.zhanshi = 1
                this.shifou_baocun = true
                this.cunchu()
              } else if (res.data.code == 10232) {}
            })
          }
        }
      }
    },
    // 调整的保存
    tiaozheng_baocun () {
      const date = this.zhanshi_tiaozheng_list
      if (date.length != 0) {
        const list = []
        const baocuo = []
        date.forEach(item => {
          if (item.bennian_mubiao_xiaoshou_e_tiaozheng.length != 0 && item.bennian_mubiao_xiaoshou_e_tiaozheng != 0) {
            if (Number(item.tiaozheng_hou_bennian_mubiao_xiaoshou_e) < 0) {
              baocuo.push('1111')
            }
          }
        })
        if (baocuo.length == 0) {
          for (let i = 0; i < date.length; i++) {
            if (date[i].bennian_mubiao_xiaoshou_e_tiaozheng != '') {
              // tiaozheng_hou_bennian_mubiao_xiaoshou_e
              list.push({
                prod_code: date[i].chanpin_bianhao.length != 0 ? date[i].chanpin_bianhao : null,
                prod_id: date[i].prod_id,
                amt_adjust: Number(date[i].bennian_mubiao_xiaoshou_e_tiaozheng),
                amt_aim: Number(date[i].tiaozheng_hou_bennian_mubiao_xiaoshou_e),
                prod_type: date[i].type == 1 ? '2' : '1',
                add_flag: date[i].add_flag
              })
            }
          }
          console.log(list)
          if (list != []) {
            update_as_prod_aim({
              data: {
                ent_id: this.$ent_id(),
                year: this.time.slice(0, 4),
                list: list,
                user_id: this.$jichuxinxi().user_id
              }
            }).then(res => {
              console.log(res)
              if (res.data.code == 10233) {
                if (localStorage.getItem('biaoer_shijian') != null && localStorage.getItem('biaoer_shijian') != undefined && localStorage.getItem('biaoer_shijian') != '') {
                  // let data=JSON.parse(localStorage.getItem("biaoer_shijian"))
                  // for(let i=0;i<data.length;i++){
                  //     if(data[i].time==this.time){
                  //         for(let a=0;a<data[i].list.length;a++){
                  //             for(let b=0;b<list.length;b++){
                  //                 if(data[i].list[a].prod_id==list[b].prod_id){
                  //                     data[i].list[a].bennian_mubiao_xiaoshou_e=list[b].amt_aim
                  //                 }
                  //             }
                  //         }
                  //         this.zhanshi_xuanze_list=data[i].list
                  //     }
                  // }
                  this.jichu()
                  this.$message({
                    message: '调整成功',
                    type: 'success'
                  })
                  this.tiaozheng_list = false
                }
              }
              // else if(res.data.code==10234){
              //     this.$message({
              //         message: '同一条数据一天只能修改一次',
              //         type: 'warning'
              //     });
              // }
            })
          } else {
            this.$message({
              message: '请填写要调整的目标',
              type: 'warning'
            })
          }
        } else {
          this.$message({
            message: '目标不能为负值',
            type: 'warning'
          })
        }
      } else {
        this.$message({
          message: '您还没有可调整的目标呢',
          type: 'warning'
        })
      }
    },
    // 点击查询日志
    dianji_lishi_chaxun () {
      query_as_prod_aim_log({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10235) {
          const list1 = [
            {
              name: '产品编号',
              con: 'prod_code'
            },
            {
              name: '产品',
              con: 'prod_name'
            },
            {
              name: '本年目标销售额',
              con: 'amt_sale_aim'
            }
          ]
          const date = JSON.parse(res.data.body.data)
          const data = JSON.parse(res.data.body.date_list)
          console.log(date)
          console.log(data)
          if (date != null) {
            for (let i = 0; i < date.length; i++) {
              for (var a in date[i]) {
                if (date[i][a] == '-123456789.00' || date[i][a] == 'null') {
                  date[i][a] = ''
                }
              }
            }
          }
          for (let i = 0; i < data.length; i++) {
            list1.push({
              name: '本年目标销售额调整(' + data[i].slice(0, 4) + '-' + data[i].slice(4, 6) + '-' + data[i].slice(6) + ')',
              con: '调整金额' + data[i]
            })
            list1.push({
              name: '调整后本年目标销售额(' + data[i].slice(0, 4) + '-' + data[i].slice(4, 6) + '-' + data[i].slice(6) + ')',
              con: data[i]
            })
          }
          this.lishi_tit = list1
          this.lishi_list = date
          console.log(this.lishi_list, 9882882)
          this.lishi_list.forEach(item => {
            if(item[20201222] !== '') {
              item['调整金额20201222'] = item.amt_sale_aim - parseInt(Number(item[20201222]))
            }
          })
          //console.log(this.lishi_list, 879797979)
          this.lishi_yemian = true
        } else if (res.data.code == 10236) {}
      })
    },
    // 点击出图
    dianji_chutu () {
      if (this.zhanshi == 1) {
        // this.chuxian='111'
        // this.quan=this.time
        // console.log(this.chuanshu)

        this.$refs.mubiao_tu2.dakai_quanping(
          {
            time: this.time,
            chutu: true,
            biaoge_nei: true
          }
        )
        // this.chutu=false
      } else {
        this.$message({
          message: '请先进行保存',
          type: 'warning'
        }) 
      }
    },
    // 点击关闭
    dianji_guanbi () {
      this.$router.push('/aspreackeven')
    },
    shangnian_zengzheng (i) {
      if (Number(i.shangnian_xiaoshou_zengzhang_lv) < 1) {
        i.shangnian_xiaoshou_zengzhang_lv = 1
      } else if (Number(i.shangnian_xiaoshou_zengzhang_lv > 100)) {
        i.shangnian_xiaoshou_zengzhang_lv = 100
      }
      console.log(i.shangnian_xiaoshou_zengzhang_lv)
    },
    // bennian_mubiao_xiaolv(i){
    //     if(Number(i.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv)<1){
    //         i.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv=1
    //     }else if(Number(i.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv>100)){
    //         i.bennian_mubiao_xiaoshou_e_yuce_wancheng_lv=100
    //     }
    // },
    // 新增产品
    xinzeng_chanpin () {
      query_ent_prod_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          this.$func.tickMenuIdFilter().filter(date, this.zhanshi_tiaozheng_list.map(item => item.prod_id))
          console.log(this.zhanshi_tiaozheng_list.map(item => item.prod_id).join(','))
          this.tree = date
          this.dianji_xinzeng_chanpin = true
        } else if (res.data.code == 201) {}
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tabletwo {
    min-height:7.2rem;
    background:#F4F7FD;
    padding: 0.41rem 0.31rem 0.34rem 0.31rem;
    // width:100%;
    .beijing{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.3);
        .box1{
            height:3.03rem;
            width:6.44rem;
            background:#fff;
            border-radius: 0.2rem;
            position: absolute;
            left:50%;
            margin-left: -3.22rem;
            top:50%;
            margin-top: -1.51rem;
            .tit_box{
                font-size: 0.18rem;
                color:#1A2533;
                text-align: center;
                padding-top:0.25rem;
                margin-bottom: 0.45rem;
            }
            .con{
                font-size: 0.14rem;
                color:#4C4A4D;
                text-align: center;
                margin-bottom: 0.2rem;
            }
            .con_box{
                display: flex;
                justify-content: center;
                font-size: 0.12rem;
                color:#4C4A4D;
                align-items: center;
            }
            .foot{
                display: flex;
                justify-content: flex-end;
                margin-right: 0.6rem;
                margin-top:0.64rem;
                span{
                    display: block;
                    height:0.43rem;
                    width:0.83rem;
                    line-height: 0.43rem;
                    border:0.01rem solid #979797;
                    text-align: center;
                    font-size: 0.14rem;
                    color:#888888;
                    border-radius: 0.02rem;
                    cursor: pointer;
                    &:nth-child(1){
                        background: #9a86db;
                        border:0.01rem solid #9a86db;
                        color:#fff;
                        margin-right:0.19rem;
                    }
                }
            }
        }
    }
    .box{
        min-height:7.2rem;
        width:17.2rem;
        .box_tit{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .box_tit_left{
                display: flex;
                align-items: center;
                color:#9a86db;
                font-size: 0.12rem;
            }
            .box_tit_center{
                font-size: 0.22rem;
                color:#1A2533;
            }
            .box_tit_right{
                display: flex;
                align-items: center;
                font-size: 0.14rem;
                color:#4C4A4D;
                cursor: pointer;
                i{
                    display: block;
                    height:0.25rem;
                    width:0.28rem;
                    background: url('../../assets/rzhi_no.png') no-repeat;
                    background-position:center center;
                    margin-left: 0.1rem;
                }
                &:hover i{
                    background: url('../../assets/rzhi_ok.png') no-repeat;
                    background-position:center center;
                }
            }
        }
        .table_tit{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.54rem;
            .table_tit_left{
                display: flex;
                align-items: center;
                .xinjian{
                    font-size: 0.14rem;
                    background:#9a86db;
                    padding: 0.03rem 0.16rem;
                    border:0.01rem solid #9a86db;
                    border-radius: 0.02rem;
                    color:#fff;
                    margin-right: 0.15rem;
                    cursor: pointer;
                }
                .table_tit_left_nianfen{
                    font-size: 0.14rem;
                    color:#4c4a4d;
                }
            }
            .table_tit_right{
                font-size: 0.1rem;
                color:#444;
            }
        }
        .table{
            height:0.69rem;
            padding: 0 0.21rem;
            border:0.01rem solid #888;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top:0.2rem;
            div{
                display: flex;
                align-items: center;
                p{
                    padding: 0.03rem 0.16rem;
                    color:#838383;
                    font-size: 0.14rem;
                    border-radius: 0.02rem;
                    border:0.01rem solid #979797;
                    margin-right:0.12rem;
                    cursor: pointer;
                }
            }
            .table_right{
                p{
                    &:nth-child(2){
                        background:#9a86db;
                        color:#fff;
                        border:0.01rem solid #9a86db;
                    }
                    &:nth-child(3){
                        background:#B8C1CE;
                        color:#fff;
                        border:0.01rem solid #B8C1CE;
                    }
                }
            }
        }
        .table_con{
            min-height:1.7rem;
            // .el-table--border{
            //     border:0.01rem solid #888888;
            // }
            // .el-table td,.el-table th.is-leaf{
            //     border:0.01rem solid #888;
            // }
            // .el-table--border th{
            //     border:0.01rem solid #888;
            // }
            .shangnian{
                background:#FFEDDA;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .box {
            width:100%;
        }
    }
    .tianjia_chanpin{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:9999;
        background: rgba(0,0,0,0.3);
        .neirong{
            height:5.84rem;
            width:8.99rem;
            border-radius: 0.04rem;
            background:#fff;
            position: relative;
            left: 50%;
            top:50%;
            margin-left: -4.45rem;
            margin-top: -2.92rem;
            .neirong_tit{
                font-size: 0.22rem;
                color:#1b2634;
                text-align: center;
                padding-top: 0.24rem;
            }
            .neirong_con{
                padding: 0 0.54rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .neirong_con_left,.neirong_con_right{
                    height:3.39rem;
                    width:3.85rem;
                    background:#D8E6F7;
                    border-radius: 0.04rem;
                    margin-top: 0.6rem;
                    padding-top:0.15rem;
                    overflow-y:auto;
                    &::-webkit-scrollbar {
                        width: 0.2rem;
                        height: 0.08rem;
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        border-radius: 0.12rem;
                        border: 0.06rem solid rgba(0, 0, 0, 0);
                        box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                    }
                    
                    &::-webkit-scrollbar-thumb:hover {
                        box-shadow: 0.08rem 0 0 #4A4A4A inset;
                    }
                    position: relative;
                    .neirong_con_tit{
                        position: absolute;
                        top:-0.3rem;
                        left:0;
                        font-size: 0.14rem;
                        color:#1A2533;
                    }
                }
                .neirong_con_left{
                    input{
                        height:0.3rem;
                        width:3.04rem;
                        padding-left: 0.33rem;
                        border:none;
                        display: block;
                        margin: 0 auto;
                        border-radius:0.02rem;
                    }
                    .el-icon-search{
                        position: absolute;
                        top:0.22rem;
                        left:0.32rem;
                    }
                    .el-tree{
                        margin-top:0.2rem;
                        padding: 0 0.21rem;
                    }
                    .custom-tree-node{
                        display: flex;
                        align-items: center;
                        img{
                            height:0.14rem;
                            width:0.15rem;
                            display: inline-block;
                            margin-right:0.14rem;
                        }
                        font-size: 0.12rem;
                        color:#1A2533;
                    }
                }
                .neirong_con_right{
                    ul{
                        padding: 0 0.21rem;
                        li{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-size: 0.12rem;
                            color:#1A2533;
                            height:0.36rem;
                            cursor: pointer;
                            padding: 0 0.1rem;
                            &:hover{
                                background: #f5f7fa;
                            }
                            img{
                                height:0.12rem;
                                width:0.12rem;
                            }
                        }
                    }
                }
            }
            .neirong_con_foot{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top:0.38rem;
                span{
                    display: inline-block;
                    height:0.43rem;
                    width:0.83rem;
                    border:0.02rem;
                    font-size:0.14rem;
                    line-height: 0.43rem;
                    text-align: center;
                    margin: 0 0.28rem;
                    cursor: pointer;
                    &:nth-child(1){
                        color:#fff;
                        background: #9a86db;
                        border:0.01rem solid #9a86db;
                    }
                    &:nth-child(2){
                        color:#888;
                        background: #fff;
                        border:0.01rem solid #888;
                    }
                }
            }
        }
    }
    .tiaozheng_mubiao{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .tiaozheng_box{
            height:9rem;
            width:14.46rem;
            border-radius: 0.04rem;
            background:#fff;
            position: relative;
            left: 50%;
            top:50%;
            margin-left: -7.23rem;
            margin-top: -4.5rem;
            .tiaozheng_con{
                padding-top:0.63rem;
                width:12.46rem;
                margin: 0 auto;
                height:6rem;
                // overflow-y:auto;
                // &::-webkit-scrollbar {
                //     width: 0.2rem;
                //     height: 0.08rem;
                // }
                
                // &::-webkit-scrollbar-thumb {
                //     border-radius: 0.12rem;
                //     border: 0.06rem solid rgba(0, 0, 0, 0);
                //     box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                // }
                
                // &::-webkit-scrollbar-thumb:hover {
                //     box-shadow: 0.08rem 0 0 #4A4A4A inset;
                // }
                .tiaozheng_tit{
                    font-size: 0.18rem;
                    color:#1A2533;
                    margin-bottom: 0.28rem;
                    text-align: center;
                }
                .tiaozheng_tit_right{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    p{
                        color:#4C4A4D;
                        &:nth-child(1){
                            font-size: 0.14rem;
                        }
                        &:nth-child(2){
                            font-size: 0.1rem;
                        }
                    }
                }
                .table{
                    height:0.69rem;
                    padding: 0 0.21rem;
                    border:0.01rem solid #888;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top:0.2rem;
                    span{
                        padding: 0.03rem 0.16rem;
                        font-size: 0.14rem;
                        border-radius: 0.02rem;
                        border:0.01rem solid #979797;
                        cursor: pointer;
                        background:#9a86db;
                        color:#fff;
                        border:0.01rem solid #9a86db;
                    }
                    div{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        p{
                            padding: 0.03rem 0.16rem;
                            color:#838383;
                            font-size: 0.14rem;
                            border-radius: 0.02rem;
                            border:0.01rem solid #979797;
                            margin-right:0.12rem;
                            cursor: pointer;
                            &:nth-child(1){
                                background:#9a86db;
                                color:#fff;
                                border:0.01rem solid #9a86db;
                            }
                            &:nth-child(2){
                                background:#B8C1CE;
                                color:#fff;
                                border:0.01rem solid #B8C1CE;
                            }
                        }
                    }
                }
            }
        }
    }
    .lishi_chaxun{
        height:100%;
        width:100%;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
        z-index:999;
        background: rgba(0,0,0,0.8);
        .lishi_chaxun_box{
            height:9rem;
            width:14.46rem;
            border-radius: 0.04rem;
            background:#fff;
            position: relative;
            left: 50%;
            top:50%;
            margin-left: -7.23rem;
            margin-top: -4.5rem;
            .lishi_chaxun_con{
                padding-top:0.63rem;
                width:12.46rem;
                margin: 0 auto;
                .lishi_chaxun_tit{
                    font-size: 0.18rem;
                    color:#1A2533;
                    margin-bottom: 0.28rem;
                    text-align: center;
                }
                .lishi_chaxun_tit_right{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    p{
                        color:#4C4A4D;
                        &:nth-child(1){
                            font-size: 0.14rem;
                        }
                        &:nth-child(2){
                            font-size: 0.1rem;
                        }
                    }
                }
                .table{
                    height:0.69rem;
                    padding: 0 0.21rem;
                    border:0.01rem solid #888;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top:0.2rem;
                    p{
                        padding: 0.03rem 0.16rem;
                        color:#444;
                        font-size: 0.14rem;
                        border-radius: 0.02rem;
                        border:0.01rem solid #979797;
                        margin-right:0.12rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
